import React, { useState } from 'react';

import { QLink } from './QLink';

export const QAppIcon = ({
  app,
  platform,
  height,
  width,
  listView = false,
}) => {
  const [imageOption, setImageOption] = useState(0);

  const handleImageError = () => {
    setImageOption(imageOption + 1);
  };

  const iconUrlSources = [
    app?.farm_app?.icon_large_url,
    `data:image/png;base64,${app?.farm_app?.icon_base64}`,
    app?.farm_app?.icon_small_url,
  ].filter(iconUrl => iconUrl);

  const defaultSize = listView ? 'fa-lg' : 'fa-4x';

  if (listView) {
    iconUrlSources.reverse();
  }

  const iconUrl = iconUrlSources[imageOption];
  const appUrl = app?.farm_app?.store_url;

  const defaultIcon =
    platform === 'android' ? (
      <i
        data-testid="android-icon"
        className={`fa-brands fa-android ${defaultSize}`}
      />
    ) : (
      <i
        data-testid="ios-icon"
        className={`fa-brands fa-apple ${defaultSize}`}
      />
    );

  return (
    <QLink href={appUrl}>
      {imageOption < iconUrlSources.length ? (
        <img
          alt="App Icon"
          src={iconUrl}
          height={height}
          width={width}
          style={{ borderRadius: '13.75px' }}
          onError={handleImageError}
        />
      ) : (
        defaultIcon
      )}
    </QLink>
  );
};
